<template>
  <div>
    <div class="class_nav">
      <div class="nav_main">
        <div class="nav_main_header">
          <div class="global-search-single">
            <div class="single-left" v-if="hasPerm(['goods.brand.store'])">
              <el-button type="primary" @click="addbrand()"
                >+添加品牌</el-button
              >
              <el-button type="primary" @click="addImportManage"
                >批量上传</el-button
              >
            </div>
            <div class="single-right">
              <div class="search-item">
                <label class="search-label">添加时间:</label>
                <el-date-picker
                  v-model="valuetimes"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  format="yyyy-MM-dd"
                  value-format="timestamp"
                  end-placeholder="结束日期"
                  @change="cleartime"
                >
                </el-date-picker>
                <el-input
                  class="beandinput"
                  placeholder="请输入内容"
                  v-model="note_name"
                  ref="brandsearchtext"
                ></el-input>
              </div>
              <el-button type="primary" @click="brandsearch">搜索</el-button>
            </div>
          </div>
        </div>
        <div class="classifyTable">
          <div class="table_headed clearfloat">
            <el-select
              v-model="batchValue"
              placeholder="批量设置"
              @change="batchDelete"
            >
              <el-option
                v-for="item in batchOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.prem ? !hasPerm(item.prem) : false"
              >
              </el-option>
            </el-select>
          </div>
          <div class="brand_table">
            <el-table
              :data="tableData"
              style="width: 100%"
              ref="multipleTable"
              @selection-change="singletable"
            >
              <el-table-column width="50" type="selection"></el-table-column>
              <el-table-column
                width="100"
                prop="id"
                label="ID"
              ></el-table-column>
              <el-table-column
                width="226"
                prop="brand_code"
                label="品牌编码"
              ></el-table-column>
              <el-table-column
                width="256"
                prop="name"
                label="中文名称"
              ></el-table-column>
              <el-table-column
                width="256"
                prop="en_name"
                label="英文名称"
              ></el-table-column>
              <el-table-column width="200" label="品牌图标">
                <template slot-scope="scope">
                  <div class="goods_img">
                    <img :src="scope.row.pic_url" alt="" :onerror="errorImg" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                width="140"
                prop="sort"
                label="排序"
              ></el-table-column>
              <el-table-column
                width="200"
                prop="created_at"
                label="添加时间"
              ></el-table-column>
              <el-table-column width="150" label="是否显示">
                <template slot-scope="scope">
                  <div>{{ scope.row.is_show ? "显示" : "隐藏" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="200">
                <template slot-scope="scope">
                  <div class="caozuo">
                    <a
                      @click="brandeidt(scope.row.id)"
                      v-if="hasPerm(['goods.brand.update'])"
                      >编辑</a
                    >
                    <a
                      @click="brandelect(scope.row.id)"
                      v-if="hasPerm(['goods.brand.delete'])"
                      >删除</a
                    >
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <div class="empty_data">
                  <p>暂无数据</p>
                </div>
              </template>
            </el-table>
          </div>
        </div>

        <div class="table_foot">
          <div class="foot_left"></div>
          <div class="main_pages">
            <el-pagination
              @size-change="handleSizeChange"
              hide-on-single-page
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="eachPage"
              layout="sizes, prev, pager, next, jumper"
              :pager-count="5"
              :total="total_entry"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 导入分组 -->
    <diy-import-more
      ref="diyImportMore"
      dialogTitle="导入品牌"
      dialogImportUrl="/brand/import"
      dialogTemplateUrl="brand.import.template"
      @dialogImportCallback="importCallback"
    ></diy-import-more>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorImg:
        'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
      note_name: "", //搜索名称
      batchOptions: [
        {
          label: "批量删除",
          value: "del",
          prem: ["goods.brand.delete"],
        },
      ],
      batchValue: "",
      valuetimes: [], //选择时间
      tableData: [],
      currentPage: 1,
      eachPage: 10,
      total_entry: 0,
      multipleSelection: [], //表格中选中的数据
      branddelete: [], //删除数组
      batchSuport: false,
      leadform: "",
      timearray: [], //时间 #TODO 后期可能修改为日期格式
    };
  },
  computed: {},
  mounted: function () {
    this.brandlistapi();
  },
  methods: {
    singletable(val) {
      this.multipleSelection = val;
    },
    // 打开导入弹窗
    addImportManage() {
      this.$refs.diyImportMore.importMore = true;
    },
    // 导入成功的回调
    importCallback() {
      // 成功导入，刷新列表
      this.brandlistapi();
    },
    brandlistapi() {
      let data = {
        search: [
          {
            key: "name",
            value: this.note_name,
            op: "search",
          },
          {
            key: "created_at",
            value: this.timearray,
            op: "between",
          },
        ],
        order: [
          {
            key: "id",
            value: "desc",
          },
        ],
        page: this.currentPage,
        limit: this.eachPage,
      };
      this.$get(this.$apis.brandList, data)
        .then((res) => {
          // //console.log(res)
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.total_entry = res.data.total;
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // //console.log(err)
          this.common.message(this, err.message);
        });
    },
    handleSizeChange(val) {
      this.eachPage = val;
      this.brandlistapi();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.common.backTop();
      this.brandlistapi();
    },
    cleartime(t) {
      if (t == null) {
        this.valuetimes = []; // 这里
        return;
      }
    },
    // 搜索
    brandsearch() {
      let valuetimes = [];
      if (this.valuetimes) {
        for (let i = 0; i < this.valuetimes.length; i++) {
          valuetimes.push(this.valuetimes[i] / 1000);
        }
      }
      let searchtext = this.$refs.brandsearchtext.value;
      this.note_name = searchtext;
      this.timearray = valuetimes;
      this.currentPage = 1;
      this.brandlistapi();
    },
    // 批量删除
    batchDelete(val) {
      if (!this.multipleSelection.length) {
        this.$message({
          showClose: true,
          message: "请选中要删除的品牌",
          type: "warning",
          offset: 200,
          duration: 1000,
        });
        this.batchValue = "批量设置";
        return false;
      }
      this.branddelete = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.branddelete.push(this.multipleSelection[i].id);
      }
      let data = {
        id: this.branddelete,
      };
      this.$delete(this.$apis.delBatchBrand, data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "删除成功",
              onClose: () => {
                this.brandlistapi();
                this.branddelete = [];
                this.batchValue = "批量设置";
              },
            });
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // //console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 单个删除
    brandelect(id) {
      this.$delete(this.$apis.branDelete+'/'+id)
        .then((res) => {
          // //console.log(res)
          if (res.code == 200) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "删除成功",
              onClose: () => {
                this.brandlistapi();
                this.branddelete = [];
              },
            });
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // //console.log(err)
          this.common.message(this, err.message);
        });
    },
    addbrand() {
      this.$router.push({
        path: "/goods/brand_edit",
      });
    },
    brandeidt(id) {
      this.$router.push({
        path: "/goods/brand_edit",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.brand_table /deep/ .el-table th:first-child > .cell {
  padding-left: 14px;
}
.single-right .beandinput {
  margin-left: 10px;
}
.goods-label {
  color: #666;
}

/deep/.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #535353;
}

/deep/.el-table--scrollable-x .el-table__body-wrapper {
  background: transparent;
}

.class_nav {
  background-color: #fff;
}

.nav_main {
  padding: 20px;
}

.nav_main_header {
  padding-bottom: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: inset 0 -1px 0 #efefef;
}

.add_class {
  background: var(--fontColor);
  color: #fff;
  padding: 0 16px;
  height: 38px;
  font-size: 14px;
  margin-right: 10px;
}

.table_headed .el-select {
  width: 130px;
  margin-bottom: 10px;
  float: left;
}
.table_headed /deep/ .el-select .el-input {
  width: 100%;
}

.el-table th:first-child.el-table__cell > .cell {
  padding-left: 13px;
}

.table_headed .el-select .el-input__inner {
  height: 38px;
}

.table_headed .el-select.is-focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.table_headed .el-select:focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.table_headed .el-select:hover .el-input__inner {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.table_search {
  float: right;
}

.table_search > span {
  padding: 0 18px;
}

.table_search-input .el-input__inner {
  /* padding-right: 0; */
  width: 270px;
}

.table_foot {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand_table table th,
table td {
  border: none;
}

.brand_table .el-table .el-table__header-wrapper .el-table__header thead tr th {
  background-color: #f9f9fc;
}

.brand_table .el-table .el-table__header-wrapper {
  background-color: #f9f9fc;
}

.brand_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
  background-color: #f9f9fc;
}

.el-table th.el-table__cell {
  background-color: #f9f9fc;
  font-weight: 600;
  color: #333;
  padding: 8px 0;
}

.goods_img {
  width: 50px;
  height: 50px;
  min-width: 50px;
}

.goods_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caozuo a {
  padding: 0 10px;
  border-right: 1px solid var(--fontColor, #fb6638);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  color: var(--fontColor);
}

.caozuo a:last-child {
  border-right: none;
}

.empty_data {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.empty_data p {
  margin-top: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
}

.table_foot {
  margin-top: 10px;
}

.main_pages {
  display: flex;
  align-items: center;
}

.main_pages .el-pagination {
  display: flex;
  align-items: center;
}

.main_pages .el-pagination .el-pager {
  display: flex;
  align-items: center;
}

.main_pages .el-pagination .el-pager .number {
  min-width: 32px;
  height: 32px !important;
  line-height: 32px !important;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dcdee0;
  margin-left: 4px;
}

.main_pages .el-pagination .el-pager .number.active {
  color: #fff !important;
  background: var(--fontColor);
  border-color: var(--fontColor, #fb6638) !important;
}

.main_pages .el-pagination .el-pager .number:first-child {
  margin-left: 0;
}

.batch_wrap {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.batch_title {
  line-height: 44px;
  margin-right: 20px;
}

.batch_url .goodsbtned {
  border-radius: 0 4px 4px 0;
}

.batch_url > .el-input > .el-input-group__append {
  padding: 0;
}

.bath_tips {
  color: #d9534f;
  margin: 10px 0;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: var(--fontColor, #fb6638) !important;
}
</style>
